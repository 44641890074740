<template>
  <section class="affective-word-list-wrap">
    <section class="filter">
      <el-form :inline="true" :model="form" >
        <el-form-item label="词表名称">
          <el-input v-model="form.vocabularyName" placeholder="情感词表名称"></el-input>
        </el-form-item>
        <el-form-item label="词表中的词">
          <el-input v-model="form.WordsInTheGlossary" placeholder="情感词表包含的词语"></el-input>
        </el-form-item>
        <el-form-item label="行业范围类型">
          <el-select v-model="form.rangeType">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="词表来源">
          <el-select v-model="form.source">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="情感类型">
          <el-select v-model="form.emotionType">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用">
          <el-select v-model="form.state">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-button type="primary" size="mini">查询</el-button> -->
      </el-form>
    </section>
    <section class="content">
      <div class="btn-wrap">
        <el-button size="small" type="primary" @click="addAffective">创建</el-button>
        <!-- <el-button size="small" type="primary" @click="editAffective">编辑</el-button>
        <el-button size="small" type="primary" @click="deleteAffective">删除</el-button> -->
      </div>
        <el-table
          :data="tableData"
          @row-click="addList"
          ref="table"
          border max-height="calc(100% - 40px)"
          style="width: 100%">
          <el-table-column  :resizable="false"
            prop="vocabularyName"
            align="center"
            label="词表名称">
          </el-table-column>
          <el-table-column  :resizable="false"
            prop="rangeType"
            align="center"
            label="行业范围类型">
          </el-table-column>
          <el-table-column  :resizable="false"
            prop="source"
            align="center"
            label="词表来源">
            <template scope="{ row }">
              {{ row.source === 0 ? '系统提供(不可修改)' : '自定义' }}
            </template>
          </el-table-column>
          <el-table-column  :resizable="false"
            prop="emotionType"
            align="center"
            label="情感类型">
            <template scope="{ row }">
              <span :style="{color: row.emotionType === 1 ? '#39CA7A' : '#FF0000'}">
              {{row.emotionType === 1 ? '正面' : '负面'}}
              </span>
            </template>
          </el-table-column>
          <el-table-column  :resizable="false"
            prop="NumberOfWords"
            align="center"
            label="词语个数">
            <template scope="{ row }">
              <span style="color: #3086FF;cursor: pointer;" @click="showEmotionalwords(row)">{{ row.NumberOfWords }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column  :resizable="false"
            prop="ambiguousWords"
            align="center"
            label="歧义词个数">
            <template scope="{ row }">
              <span style="color: #3086FF;">{{ row.ambiguousWords }}</span>
            </template>
          </el-table-column> -->
          <el-table-column  :resizable="false"
            prop="state"
            align="center"
            label="启用">
            <template scope="{ row }">
              <el-switch
                style="display: block"
                v-model="row.state"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column  :resizable="false"
            prop="crateTime"
            align="center"
            label="创建时间">
          </el-table-column>
          <el-table-column  :resizable="false"
            label="操作"
            align="center">
            <template scope="{ row }">
              <el-button type="text" :disabled="row.source === 0 ? true : false" @click="editAffective(row.id)">编辑</el-button>
              <el-button type="text" :disabled="row.source === 0 ? true : false" @click="deleteAffective(row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <section class="list-bottom">
          <span class="count">共{{count}}条记录</span>
          <el-pagination
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count">
          </el-pagination>
        </section>
    </section>
    <el-dialog
      :title="type === 'add' ? '新增词表' : '修改词表'"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form label-width="80px" :model="alterForm" ref="alterForm">
        <el-form-item label="词表名称:" prop="vocabularyName">
          <el-input v-model="alterForm.vocabularyName"></el-input>
        </el-form-item>
        <el-form-item label="行业类型:" prop="rangeType">
          <el-select v-model="alterForm.rangeType">
            <el-option v-for="item in industryList" :label="item.name" :value="item.name" :key="item.name">{{ item.name }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="情感类型:" prop="emotionType">
          <el-select v-model="alterForm.emotionType">
            <el-option value="0" label="负面"></el-option>
            <el-option value="1" label="正面"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="type === 'add' ? '新增情感词' : type === 'edit' ? '修改情感词' : type === 1 ? '提示' : '情感词表'"
      :visible.sync="tableVisible"
      width="40%"
      center before-close="handleClose">
      <template>
        <div v-if="type === 0">
          <el-button type="primary" size="mini" @click="addEmotionalWord"
          style="margin-bottom: 10px;" v-if="form.source === 1">添加</el-button>
          <el-table :data="dataList" border style="width: 100%">
            <el-table-column  :resizable="false" prop="name" label="情感词" align="center">
            </el-table-column>
            <el-table-column  :resizable="false" prop="mark" label="评分" width="80" align="center">
            </el-table-column>
            <el-table-column  :resizable="false" label="操作" align="center" v-if="form.source === 1">
              <template scope="{ row }">
                <el-button type="text" @click="editEmotionalWord(row)">编辑</el-button>
                <el-button type="text" @click="deleteEmotionalWord(row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else-if="type === 1 ">
          <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
        </div>
        <div v-else>
          <el-form label-width="90px" :model="addForm" ref="addForm"
          >
            <el-form-item label="情感词名称">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
            <el-form-item label="评分">
              <el-input v-model="addForm.mark"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer" v-if="type !== 0">
          <el-button @click="type = 0;addForm = {}">取 消</el-button>
          <el-button type="primary" @click="confirmDelete" v-if="type ===1">确定</el-button>
          <el-button type="primary" @click="emotionSubmit" v-else>保存</el-button>
        </span>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { allIndustry } from '@/api/settings/project'
export default {
  mounted () {
    eventHub.$emit('msg', '情感词表')
    this.showAllIndustry()
  },
  data () {
    return ({
      type: 0,
      dialogVisible: false,
      tableVisible: false,
      industryList: [],
      dataList: [
        {
          id: 1,
          name: 'asd',
          mark: '80'
        }
      ],
      addForm: {
        name: '',
        mark: ''
      },
      alterForm: {
        vocabularyName: '',
        rangeType: '',
        source: 1,
        emotionType: '',
      },
      form: {
        vocabularyName: '',
        WordsInTheGlossary: '',
        rangeType: '',
        source: '',
        emotionType: '',
        state: ''
      },
      tableData: [
        {
          id: 1,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 1,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        {
          id: 2,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 0,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        {
          id: 3,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 1,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        {
          id: 4,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 1,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        {
          id: 5,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 1,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        {
          id: 7,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 1,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        {
          id: 8,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 0,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        {
          id: 9,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 1,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        {
          id: 10,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 0,
          emotionType: 0,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        },
        { id: 11,
          vocabularyName: '地产负面词',
          rangeType: '地产',
          source: 0,
          emotionType: 1,
          NumberOfWords: 123,
          ambiguousWords: 123,
          state: true,
          crateTime: '2019-11-06 15:32'
        }
      ],
      count: 11,
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      selectedList: []
    })
  },
  methods: {
    showEmotionalwords (data) {
      this.tableVisible = true
      this.form = data
    },
    handleClose () {
      this.type = 0
    },
    addEmotionalWord () {
      this.type = 'add'
    },
    editEmotionalWord (data) {
      this.type = 'edit'
      this.addForm = data
    },
    deleteEmotionalWord (id) {
      this.type = 1
    },
    emotionSubmit () {
      this.type = 0
      this.addForm = {}
    },
    confirmDelete () {
      this.type = 0
    },
    async showAllIndustry () {
      const res = await allIndustry()
      console.log(res)
      this.industryList = res.data.list
    },
    addAffective () {
      this.type = 'add'
      this.dialogVisible = true
    },
    onSubmit () {},
    editAffective (id) {
      this.dialogVisible = true
    },
    deleteAffective (id) {
      this.$confirm('确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
      }).then(() => {
        console.log(id)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {

      });
    },
    addList (row) {
      let index = null
      this.tableData.forEach((item, i) => {
        if (item.id === row.id) {
          index = i
        }
      })
      if (!this.selectedList.includes(row.id)) {
        this.$refs.table.bodyWrapper.querySelector(`tbody .el-table__row:nth-child(${index + 1})`).classList.add('selected')
        this.selectedList.push(row.id)
      } else {
        this.$refs.table.bodyWrapper.querySelector(`tbody .el-table__row:nth-child(${index + 1})`).classList.remove('selected')
        this.selectedList.splice(this.selectedList.indexOf(row.id), 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped="">
.affective-word-list-wrap {
  width: 100%;
  height: 100%;
  .filter {
    width: 100%;
    height: 160px;
    padding: 20px;
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:10px;
    margin-bottom: 30px;
    .el-form {
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        width: 32%;
        .el-form-item__content{
          width:70%;
        }
        /* .el-input {
          width: 222px;
        } */
      }
    }
  }
  .content {
    width: 100%;
    min-height: calc(100% - 190px);
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:10px;
    padding: 20px;
    position: relative;
    .btn-wrap {
      margin-bottom: 25px;
    }
    .el-table {
      margin-bottom: 50px;
    }
    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .count {
        line-height: 32px;
      }
    }
  }
  .selected {
    background: rgba(64,158,255, .2);
  }

}
</style>
